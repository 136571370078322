import { IonIcon, IonLabel, IonRow } from '@ionic/react'
import { useContext } from 'react'
import { AuthContext } from '../../auth'
import { chevronForward } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { colors } from '../../theme/colors'

const styles = {
  headingContainer: {
    padding: '1.5rem 1.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  packageCard: {
    cursor: 'pointer',
    width: '100%',
    padding: '1.5rem',
    border: '1px solid #eaeaea',
    margin: '0.75rem 1rem',
    borderRadius: '1rem',
    backgroundColor: 'white',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
    transition: 'all 0.2s ease',
    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
    },
  },
  packageTitle: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#2d333f',
    margin: 0,
    marginBottom: '0.5rem',
  },
  priceText: {
    fontSize: '1.75rem',
    fontWeight: '600',
    color: '#2d333f',
    margin: 0,
  },
  priceSubtext: {
    fontSize: '0.9rem',
    color: '#717171',
    fontWeight: '400',
  },
  description: {
    fontSize: '1rem',
    color: '#717171',
    marginBottom: '1rem',
    lineHeight: '1.5',
  },
  featuresList: {
    margin: '1rem 0',
    padding: 0,
    listStyle: 'none',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
    fontSize: '0.95rem',
    color: '#2d333f',
  },
  ctaButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.75rem 1rem',
    backgroundColor: colors.primaryBlue,
    color: 'white',
    borderRadius: '0.5rem',
    marginTop: '1rem',
    transition: 'background-color 0.2s ease',
  },
}

export default function PackagesListView({ packages = [], openRegisterModal, locationName }) {
  const { firebaseUser } = useContext(AuthContext)
  const history = useHistory()

  const handleSelectedPackage = item => {
    if (!firebaseUser) {
      openRegisterModal()
    } else {
      history.push(`/subscription/${item._id}`)
    }
  }

  return (
    <>
      <div style={styles.headingContainer}>
        <h2 style={{ fontSize: '2rem', fontWeight: '600', margin: 0, color: '#2d333f' }}>
          {locationName} Packages
        </h2>
        <p style={{ textAlign: 'center', color: '#717171', fontSize: '1.1rem', marginTop: '0.5rem' }}>
          Get access to Unlimited Haircuts and awesome member perks
        </p>
      </div>
      {packages &&
        packages.map((packageItem) => {
          if (packageItem?.prices?.length === 0 || !packageItem.enabled) return null
          return (
            <IonRow onClick={() => handleSelectedPackage(packageItem)} style={{ width: '100%' }}>
              <div style={styles.packageCard}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <h3 style={styles.packageTitle}>{packageItem.name}</h3>
                  <div style={{ textAlign: 'right' }}>
                    <div style={styles.priceText}>
                      ${packageItem.price || getCheapestMonthlyCost(packageItem.prices)}
                      <span style={styles.priceSubtext}>/mo</span>
                    </div>
                    <div style={styles.priceSubtext}>w/ prepay</div>
                  </div>
                </div>
                
                <p style={styles.description}>{packageItem.description}</p>
                
                <ul style={styles.featuresList}>
                  {packageItem.features &&
                    packageItem.features.map((service, index) => (
                      <li key={index} style={styles.featureItem}>
                        <span style={{ marginRight: '0.5rem', color: colors.primaryBlue }}>✓</span>
                        {service}
                      </li>
                    ))}
                </ul>

                <div style={styles.ctaButton}>
                  <span style={{ marginRight: '0.5rem' }}>
                    {!firebaseUser ? `Login To Get ${packageItem.name}` : 'Learn More'}
                  </span>
                  <IonIcon icon={chevronForward} />
                </div>
              </div>
            </IonRow>
          )
        })}
    </>
  )
}

function getCheapestMonthlyCost(packages) {
  let cheapestMonthlyCost = Infinity

  for (const pkg of packages) {
    let costPerMonth = pkg.amount / pkg.interval_count

    if (costPerMonth < cheapestMonthlyCost) {
      cheapestMonthlyCost = costPerMonth
    }
  }

  // Round to the nearest 2 digits
  cheapestMonthlyCost = Math.round(cheapestMonthlyCost * 100) / 100

  // If it's a whole number, return it as such
  if (Number.isInteger(cheapestMonthlyCost)) {
    return cheapestMonthlyCost
  } else {
    return cheapestMonthlyCost.toFixed(2)
  }
}
